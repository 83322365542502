import LocomotiveScroll from "locomotive-scroll";

const scroll = new LocomotiveScroll({
  el: document.querySelector("[data-scroll-container]"),
  smooth: true,
  tablet: {
    smooth: true,
  },
});

//Fix de l'espace blanc en bas de page
new ResizeObserver(() => scroll.update()).observe(
  document.querySelector("[data-scroll-container]")
);

document.documentElement.classList.add("is-loaded"),
  document.documentElement.classList.remove("is-loading"),
  setTimeout(function () {
    document.documentElement.classList.add("is-ready");
  }, 300);

let lastScrollTop = 0;

scroll.on("scroll", (args) => {
  const navbar = document.getElementById("navbar");
  const mobileNavbar = document.getElementById("mobile-navbar");
  const feed = document.getElementById("feed-instagram");
  const scrollTop = document.getElementById("scroll-top");

  const currentScrollTop = args.scroll.y;

  if (currentScrollTop > 100) {
    navbar.classList.add("sticky");
    mobileNavbar.classList.add("mobile-navbar-sticky");
    scrollTop.classList.add("active-scroll-top");
  } else {
    navbar.classList.remove("sticky");
    mobileNavbar.classList.remove("mobile-navbar-sticky");
    scrollTop.classList.remove("active-scroll-top");
  }

  lastScrollTop = currentScrollTop;

  // Get all current elements : args.currentElements
  if (
    typeof args.currentElements["feed"] === "object" &&
    window.innerWidth > 768
  ) {
    feed.style.left = "-" + args.currentElements["feed"].progress * 500 + "px";
  }

  // const background = document.querySelectorAll(".cell .img");
  // if (window.innerWidth < 768) {
  //   background.forEach(function (element) {
  //     element.style.backgroundPositionY = -args.scroll.y * 0.1 + "px";
  //   });
  // }
});

const menu = document.getElementById("menu");
const menu2 = document.getElementById("menu2");
const mobileMenu = document.getElementById("mobile-menu");
const mobileMenuRow = document.querySelector(".mobile-menu-row");
const enfant = document.querySelectorAll(
  ".mobile-menu-text_wrapper .firstRow a"
);

function toggleMobileMenu() {
  mobileMenu.classList.toggle("active");
  mobileMenuRow.classList.toggle("animate");
}

menu.addEventListener("click", toggleMobileMenu);
menu2.addEventListener("click", toggleMobileMenu);

enfant.forEach(function (element) {
  element.addEventListener("click", () => {
    mobileMenu.classList.remove("active");
    mobileMenuRow.classList.remove("animate");
  });
});

/////// Prestations
// Audio
const audioElements = [
  {
    audio: document.getElementById("clubSong"),
    cell: document.querySelector(".clubSong"),
    pause: document.querySelector(".clubSongPause"),
  },
  {
    audio: document.getElementById("croisiereSong"),
    cell: document.querySelector(".croisiereSong"),
    pause: document.querySelector(".croisiereSongPause"),
  },
];

// function handleMouseEvents(audioElement) {
//   const { audio, cell, pause } = audioElement;

//   cell.addEventListener("click", () => {
//     audio.play();
//     cell.style.display = "none";
//     pause.style.display = "block";
//   });

//   pause.addEventListener("click", () => {
//     audio.pause();
//     audio.currentTime = 0;
//     cell.style.display = "block";
//     pause.style.display = "none";
//   });
// }

// audioElements.forEach(handleMouseEvents);

// Contact
document.getElementById("send").addEventListener("click", function () {
  var nom = document.getElementById("name").value;
  var surname = document.getElementById("surname").value;
  var telephone = document.getElementById("phone").value;

  var mailtoLink =
    "mailto:contact@flosax.com?subject=Demande de contact&body=Nom: " +
    nom +
    " " +
    surname +
    "%0A%0ATéléphone: " +
    telephone;

  window.location.href = mailtoLink;
});
